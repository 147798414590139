<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <validation-observer>
      <v-card>
        <v-card-title primary-title />
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-row
                v-for="(value, index) in fields1"
                :key="index"
                class="mt-10 align"
              >
                <v-col
                  v-if="value.type !== 'spacer'"
                  class="text-button align-self-center pt-0"
                  cols="4"
                >
                  <div>
                    <span class="font-weight-bold">
                      {{
                        $t('messages.' + value.key)
                      }}
                    </span>
                    <span class="red--text">
                      {{
                        value.validate
                          ? value.validate.includes('required')
                            ? '*'
                            : ''
                          : ''
                      }}
                    </span>
                  </div>
                </v-col>
                <v-col
                  class="text-button align-self-center pb-0 pt-0"
                  cols="7"
                >
                  <v-textarea
                    v-if="value.type === 'textarea'"
                    v-model="value.value"
                    :placeholder="$t(value.placeholder)"
                    outlined
                  />
                  <validation-provider
                    v-else-if="value.type === 'date-text'"
                    v-slot="{ errors }"
                    :name="value.key"
                    :rules="value.validate ? value.validate : ''"
                  >
                    <div v-if="'menu' in value">
                      <v-row>
                        <v-col cols="8">
                          <v-text-field
                            v-model="value.value"
                            :name="value.key"
                            outlined
                            autocomplete="off"
                            :error-messages="errors[0] ? errors[0] : value.customerValidate"
                            :placeholder="$t(value.placeholder)"
                            readonly
                            @keydown="checkDate"
                          >
                            <template v-slot:append />
                          </v-text-field>
                        </v-col>
                        <v-col cols="1">
                          <v-menu
                            :ref="'menu' + value.key"
                            v-model="value.menu"
                            :close-on-content-click="false"
                            :return-value.sync="value.value"
                            transition="scale-transition"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="mt-4 mr-3"
                                v-bind="attrs"
                                left
                                v-on="on"
                              >
                                mdi-calendar
                              </v-icon>
                            </template>
                            <v-date-picker
                              v-model="value.value"
                              locale="ja-jn"
                              no-title
                              scrollable
                              :max="value.key === 'dob' ? value.max_date : ''"
                            >
                              <v-spacer />
                              <v-btn
                                text
                                color="primary"
                                @click="value.menu = false"
                              >
                                {{ $t('messages.cancel') }}
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="saveValueDate(value)"
                              >
                                {{ $t('messages.ok') }}
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :ref="'date' + value.key"
                            disabled
                            outlined
                            :value="value.value.length > 0 ? value.key === 'dob' ? Math.round(new Date().getFullYear() - new Date(value.value).getFullYear()) : expiryDate(value.value) : '0'"
                          />
                        </v-col>
                      </v-row>
                    </div>
                    <div v-else>
                      <v-text-field
                        v-model="value.value"
                        :name="value.key"
                        outlined
                        :error-messages="errors[0] ? errors[0] : value.customerValidate"
                        :placeholder="$t(value.placeholder)"
                        @keydown="checkDate"
                      />
                    </div>
                  </validation-provider>
                  <validation-provider
                    v-else-if="value.type === 'select'"
                    v-slot="{ errors }"
                    :name="value.key"
                    :rules="value.validate ? value.validate : ''"
                  >
                    <v-select
                      v-model="value.value"
                      :name="value.key"
                      outlined
                      :error-messages="errors[0] ? errors[0] : value.customerValidate"
                      :items="value.items"
                      item-text="text"
                      item-value="value"
                      :placeholder="$t(value.placeholder)"
                    />
                  </validation-provider>
                  <validation-provider
                    v-else-if="value.type === 'text'"
                    v-slot="{ errors }"
                    :name="value.key"
                    :rules="value.validate ? value.validate : ''"
                  >
                    <v-text-field
                      v-model="value.value"
                      :disabled="value.disable"
                      :placeholder="$t(value.placeholder)"
                      :name="value.key"
                      outlined
                      :error-messages="errors[0] ? errors[0] : value.customerValidate"
                    />
                  </validation-provider>
                  <validation-provider
                    v-else-if="value.type === 'checkbox'"
                    v-slot="{ errors }"
                    :name="value.key"
                    :rules="value.validate ? value.validate : ''"
                  >
                    <v-row>
                      <v-col cols="3">
                        <v-checkbox
                          v-model="value.value"
                          :name="value.key"
                          :error-messages="errors[0]"
                          color="#0F56B3"
                        />
                      </v-col>
                    </v-row>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row />
            </v-col>
            <v-col cols="6">
              <v-row
                v-for="(value, index) in fields2"
                :key="index"
                class="align"
              >
                <v-col
                  v-if="value.type !== 'spacer'"
                  class="text-button align-self-center pt-0"
                  cols="4"
                >
                  <div>
                    <span class="font-weight-bold">
                      {{
                        $t('messages.' + value.key)
                      }}
                    </span>
                    <span class="red--text">
                      {{
                        value.validate
                          ? value.validate.includes('required')
                            ? '*'
                            : ''
                          : ''
                      }}
                    </span>
                  </div>
                </v-col>
                <v-col
                  class="text-button align-self-center pb-0 pt-0"
                  cols="7"
                >
                  <v-textarea
                    v-if="value.type === 'textarea'"
                    v-model="value.value"
                    :placeholder="$t(value.placeholder)"
                    outlined
                  />
                  <validation-provider
                    v-else-if="value.type === 'date-text'"
                    v-slot="{ errors }"
                    :name="value.key"
                    :rules="value.validate ? value.validate : ''"
                  >
                    <div v-if="'menu' in value">
                      <v-row>
                        <v-col cols="8">
                          <v-text-field
                            v-model="value.value"
                            :name="value.key"
                            outlined
                            autocomplete="off"
                            :error-messages="errors[0]"
                            :placeholder="$t(value.placeholder)"
                            :disabled="value.disable"
                            readonly
                            @keydown="checkDate"
                          >
                            <template v-slot:append />
                          </v-text-field>
                        </v-col>
                        <v-col cols="1">
                          <v-menu
                            :ref="'menu' + value.key"
                            v-model="value.menu"
                            :disabled="value.disable"
                            :close-on-content-click="false"
                            :return-value.sync="value.value"
                            transition="scale-transition"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="mt-4 mr-3"
                                v-bind="attrs"
                                left
                                v-on="on"
                              >
                                mdi-calendar
                              </v-icon>
                            </template>
                            <v-date-picker
                              v-model="value.value"
                              locale="ja-jn"
                              no-title
                              scrollable
                              :max="value.key === 'dob' ? value.max_date : ''"
                            >
                              <v-spacer />
                              <v-btn
                                text
                                color="primary"
                                @click="value.menu = false"
                              >
                                {{ $t('messages.cancel') }}
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="saveValueDate(value)"
                              >
                                {{ $t('messages.ok') }}
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :ref="'date' + value.key"
                            disabled
                            outlined
                            :value="value.value.length > 0 ? value.key === 'dob' ? Math.round(new Date().getFullYear() - new Date(value.value).getFullYear()) : expiryDate(value.value) : '0'"
                          />
                        </v-col>
                      </v-row>
                    </div>
                    <div v-else>
                      <v-text-field
                        v-model="value.value"
                        :name="value.key"
                        outlined
                        :error-messages="errors[0]"
                        :placeholder="$t(value.placeholder)"
                        :disabled="value.disable"
                        @keydown="checkDate"
                      />
                    </div>
                  </validation-provider>
                  <validation-provider
                    v-else-if="value.type === 'select'"
                    v-slot="{ errors }"
                    :name="value.key"
                    :rules="value.validate ? value.validate : ''"
                  >
                    <v-select
                      v-model="value.value"
                      :name="value.key"
                      outlined
                      :error-messages="errors[0] ? errors[0] : checkCollegeId"
                      :items="value.items"
                      item-text="text"
                      item-value="value"
                      :placeholder="$t(value.placeholder)"
                    />
                  </validation-provider>
                  <validation-provider
                    v-else-if="value.type === 'autocomplete'"
                    v-slot="{ errors }"
                    :name="value.key"
                    :rules="value.validate ? value.validate : ''"
                  >
                    <v-autocomplete
                      v-model="value.value"
                      :name="value.key"
                      :items="value.items"
                      :disabled="value.disable"
                      :placeholder="$t(value.placeholder)"
                      :error-messages="errors[0]"
                      item-text="text"
                      item-value="value"
                      clearable
                      single-line
                      outlined
                    />
                  </validation-provider>
                  <validation-provider
                    v-else-if="value.type === 'text'"
                    v-slot="{ errors }"
                    :name="value.key"
                    :rules="value.validate ? value.validate : ''"
                  >
                    <v-text-field
                      v-model="value.value"
                      :disabled="value.disable"
                      :placeholder="$t(value.placeholder)"
                      :name="value.key"
                      outlined
                      :error-messages="errors[0]"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <div class="mt-3">
                <v-row
                  v-for="(value, index) in fields3"
                  :key="index"
                  class="align"
                >
                  <v-col
                    v-if="value.type !== 'spacer'"
                    class="text-button align-self-center pt-0"
                    cols="4"
                  >
                    <div>
                      <span class="font-weight-bold">
                        {{
                          $t('messages.' + value.key)
                        }}
                      </span>
                      <span class="red--text">
                        {{
                          value.validate
                            ? value.validate.includes('required')
                              ? '*'
                              : ''
                            : ''
                        }}
                      </span>
                    </div>
                  </v-col>
                  <v-col
                    class="text-button align-self-center pb-0 pt-0"
                    cols="7"
                  >
                    <v-textarea
                      v-if="value.type === 'textarea'"
                      v-model="value.value"
                      :placeholder="$t(value.placeholder)"
                      outlined
                    />
                    <validation-provider
                      v-else-if="value.type === 'date-text'"
                      v-slot="{ errors }"
                      :name="value.key"
                      :rules="value.validate ? value.validate : ''"
                    >
                      <div v-if="'menu' in value">
                        <v-row>
                          <v-col cols="8">
                            <v-text-field
                              v-model="value.value"
                              :name="value.key"
                              outlined
                              autocomplete="off"
                              :error-messages="errors[0] ? errors[0] : value.customerValidate"
                              :placeholder="$t(value.placeholder)"
                              readonly
                              @keydown="checkDate"
                            >
                              <template v-slot:append />
                            </v-text-field>
                          </v-col>
                          <v-col cols="1">
                            <v-menu
                              :ref="'menu' + value.key"
                              v-model="value.menu"
                              :close-on-content-click="false"
                              :return-value.sync="value.value"
                              transition="scale-transition"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  class="mt-4 mr-3"
                                  v-bind="attrs"
                                  left
                                  v-on="on"
                                >
                                  mdi-calendar
                                </v-icon>
                              </template>
                              <v-date-picker
                                v-model="value.value"
                                locale="ja-jn"
                                no-title
                                scrollable
                                :max="value.key === 'dob' ? value.max_date : ''"
                              >
                                <v-spacer />
                                <v-btn
                                  text
                                  color="primary"
                                  @click="value.menu = false"
                                >
                                  {{ $t('messages.cancel') }}
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="saveValueDate(value)"
                                >
                                  {{ $t('messages.ok') }}
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="3">
                            <v-text-field
                              :ref="'date' + value.key"
                              disabled
                              outlined
                              :value="value.value.length > 0 ? value.key === 'dob' ? Math.round(new Date().getFullYear() - new Date(value.value).getFullYear()) : expiryDate(value.value) : '0'"
                            />
                          </v-col>
                        </v-row>
                      </div>
                      <div v-else>
                        <v-text-field
                          v-model="value.value"
                          :name="value.key"
                          outlined
                          :error-messages="errors[0] ? errors[0] : value.customerValidate"
                          :placeholder="$t(value.placeholder)"
                          @keydown="checkDate"
                        />
                      </div>
                    </validation-provider>
                    <validation-provider
                      v-else-if="value.type === 'select'"
                      v-slot="{ errors }"
                      :name="value.key"
                      :rules="value.validate ? value.validate : ''"
                    >
                      <v-select
                        v-if="value.key === 'visaId'"
                        v-model="visaId"
                        :name="value.key"
                        outlined
                        :error-messages="
                          errors[0] ? errors[0] : value.customerValidate
                        "
                        :items="value.items"
                        item-text="text"
                        item-value="value"
                        :placeholder="$t(value.placeholder)"
                        :disabled="value.disable"
                        @change="getVisaDocs"
                      />
                      <v-select
                        v-else
                        v-model="value.value"
                        :name="value.key"
                        outlined
                        :error-messages="
                          errors[0] ? errors[0] : value.customerValidate
                        "
                        :items="value.items"
                        item-text="text"
                        item-value="value"
                        :placeholder="$t(value.placeholder)"
                        :disabled="value.disable"
                      />
                    </validation-provider>
                    <validation-provider
                      v-else-if="value.type === 'text'"
                      v-slot="{ errors }"
                      :name="value.key"
                      :rules="value.validate ? value.validate : ''"
                    >
                      <v-text-field
                        v-if="value.key === 'language'"
                        v-model="language"
                        :placeholder="$t(value.placeholder)"
                        :name="value.key"
                        outlined
                        :error-messages="
                          errors[0] ? errors[0] : value.customerValidate
                        "
                        clearable
                      />
                      <v-text-field
                        v-else
                        v-model="value.value"
                        :disabled="value.disable"
                        :placeholder="$t(value.placeholder)"
                        :name="value.key"
                        outlined
                        :error-messages="
                          errors[0] ? errors[0] : value.customerValidate
                        "
                        clearable
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
              <div
                class="pa-3"
                style="border: 1px solid; border-radius: 10px;"
              >
                <v-row class="d-flex">
                  <v-col>
                    <span class="title-bank">{{ $t('messages.accountInformation') }}</span>
                  </v-col>
                </v-row>
                <v-row
                  v-for="(value, index) in fields4"
                  :key="index"
                  class="align"
                >
                  <v-col
                    v-if="value.type !== 'spacer'"
                    class="text-button align-self-center pt-0"
                    cols="4"
                  >
                    <div>
                      <span class="font-weight-bold">
                        {{
                          $t('messages.' + value.key)
                        }}
                      </span>
                    </div>
                  </v-col>
                  <v-col
                    class="text-button align-self-center pb-0 pt-0"
                    cols="7"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="value.key"
                      :rules="value.validate ? value.validate : ''"
                    >
                      <v-text-field
                        v-model="value.value"
                        :placeholder="$t(value.placeholder)"
                        :name="value.key"
                        outlined
                        :error-messages="errors[0]"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-card
            v-if="fields1[7].value"
            class="mt-10"
          >
            <v-card-text>
              <v-row>
                <v-col
                  class="text-button align-self-center pt-0"
                  cols="1"
                >
                  <div>
                    <span class="font-weight-bold">
                      {{
                        $t('messages.customerName')
                      }}
                    </span>
                  </div>
                </v-col>
                <v-col cols="3">
                  <validation-provider
                    name="customerName"
                    rules="required"
                  >
                    <div>
                      <v-autocomplete
                        v-model="customerName"
                        name="customerName"
                        :error-messages="!customerName ? errorCustomerName : ''"
                        :items="itemsCustomer"
                        :item-text="item => item.code == null ? item.name : item.code +' - '+ item.name"
                        item-value="id"
                        clearable
                        single-line
                        solo
                      />
                    </div>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row
                style="border-radius:10px; border:1px solid #25ACD8"
              >
                <v-col cols="11">
                  <v-row
                    v-for="(item, index) in dateInWorks"
                    :key="index"
                    style="border-radius:10px; border:1px solid #25ACD8"
                    class=" d-flex justify-space-beetween"
                  >
                    <v-col
                      class="text-button align-self-center pt-0"
                      cols="1"
                    >
                      <div>
                        <span class="font-weight-bold">
                          {{
                            $t('messages.workDay')
                          }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="3">
                      <v-btn-toggle
                        v-model="item.workDate"
                        multiple
                      >
                        <v-btn
                          v-for="(i, index1) in itemsDay"
                          :key="index1"
                          class="mr-1"
                          color="#81bfff"
                        >
                          {{ i.text }}
                        </v-btn>
                      </v-btn-toggle>
                      <label
                        v-if="item.workDate < 1 && item.errorWorkDate"
                        class="red--text"
                      >
                        {{ $t('validator.required') }}
                      </label>
                    </v-col>
                    <v-col
                      class="text-button align-self-center pt-0"
                      cols="1"
                      offset="1"
                    >
                      <div>
                        <span class="font-weight-bold">
                          {{
                            $t('messages.jobName')
                          }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="3">
                      <validation-provider
                        name="jobId"
                        rules="required"
                      >
                        <v-select
                          v-model="item.jobId"
                          item-text="name"
                          item-value="id"
                          :error-messages="!item.jobId ? item.errorJobId : ''"
                          :items="listJobs"
                          name="jobId"
                          solo
                          @change="changeJob(item.jobId, index)"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col
                      class="text-button align-self-center pt-0"
                      cols="1"
                    >
                      <div>
                        <span class="font-weight-bold">
                          {{
                            $t('messages.shift')
                          }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="2">
                      <v-select
                        v-model="item.shiftId"
                        :error-messages="!item.shiftId ? item.errorShiftId : ''"
                        :items="item.listShift"
                        :item-text="s => s.shiftStartAt + ' ~ ' + s.shiftEndAt"
                        item-value="id"
                        solo
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1">
                  <v-btn @click="addFields">
                    +
                  </v-btn>
                  <v-btn
                    v-if="dateInWorks.length > 1"
                    class="mt-2"
                    @click="subtractFields"
                  >
                    -
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-row class="d-flex justify-end mt-5">
            <v-col
              cols="4"
              class="d-flex justify-end"
            >
              <div
                class="pa-16 align-center chip ml-0"
                style="background: #c9f7f5; color: #1bc4bd"
                @click="dialogCheckFile = true"
              >
                <v-row class="d-flex">
                  <p class="1 && mr-auto">
                    {{ $t("button.confirmFile") }}
                  </p>
                  <p>{{ lengthFiles }}</p>
                </v-row>
                <v-row>
                  <v-progress-linear
                    background-color="rgba(105, 147, 255, 0.2)"
                    color="#1bc4bd"
                    :value="processCountFile"
                  />
                </v-row>
              </div>
            </v-col>

            <v-col
              cols="4"
              class="d-flex justify-end"
            >
              <div
                class="pa-16 align-center chip"
                style="background:#E1E9FF; color: #6993FF"
                @click="openCheckDoc"
              >
                <v-row class="d-flex">
                  <p class="1 && mr-auto">
                    {{ $t("button.confirmDoc") }}
                  </p>
                  <p>{{ lengthDocs }}</p>
                </v-row>
                <v-row>
                  <v-progress-linear
                    background-color="rgba(105, 147, 255, 0.2)"
                    color="#6993FF"
                    :value="processCount"
                  />
                </v-row>
              </div>
            </v-col>
          </v-row>

          <v-row align="center" />
        </v-card-text>
        <v-card-actions />
      </v-card>
      <!--button-->
      <v-row class="d-flex mt-3">
        <!-- <v-col
          cols="2"
          :class="1 && 'mr-auto'"
        >
          <v-btn
            large
            block
            color="info"
            @click="dialogOcr = true"
          >
            <span class="text-h5 pr-3 pl-3">{{ $t('button.ocr') }}</span>
          </v-btn>
        </v-col> -->
        <v-col
          cols="2"
          class="d-flex justify-space-between"
        >
          <v-btn
            large
            block
            color="info"
            type="submit"
            @click="onSubmit"
          >
            <span class="text-h5 pr-3 pl-3">{{ $t('messages.save') }}</span>
          </v-btn>
        </v-col>
        <v-col
          cols="2"
          class="d-flex justify-space-between"
        >
          <v-btn
            large
            block
            color="info"
            type="submit"
            @click="$router.push('/parttimer-list/')"
          >
            <span class="text-h5 pr-3 pl-3">{{ $t('button.cancel') }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </validation-observer>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <!-- ocr dialog -->
    <v-dialog
      v-model="dialogOcr"
      width="500"
      persistent
    >
      <qrcode />
    </v-dialog>
    <v-dialog
      v-model="dialogCheckDoc"
      persistent
      max-width="400px"
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-row
              v-for="(value, index) in docsList"
              :key="index"
              class="align"
            >
              <v-col
                class="text-button align-self-center pb-0 pt-0"
                cols="8"
              >
                <v-checkbox
                  v-model="value.isFulfiled"
                  :label="value.name"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            large
            @click="saveDocs"
          >
            {{ $t('button.save') }}
          </v-btn>
          <v-btn
            color="dark darken-1"
            large
            @click="dialogCheckDoc = false"
          >
            {{ $t('button.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialog-check-file
      :id="idParttime"
      :dialog-check-file="dialogCheckFile"
      :is-add="true"
      @closeDialogFile="closeDialogFile"
      @handleUpdateLengthFiles="hanldleUpdateLengFile"
      @clearIdPartTime="handleClearIdParttime"
    />
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
  import { required, email, min } from 'vee-validate/dist/rules'
  import moment from 'moment'
  import Qrcode from './Qrcode'
  import DialogCheckFile from '../../components/DialogCheckFile'
  import { debounce } from 'lodash'

  export default {
    name: 'ParttimerAdd',
    components: {
      ValidationProvider,
      ValidationObserver,
      Qrcode,
      DialogCheckFile,
    },
    data () {
      return {
        age: '',
        residenceCardAvailable: '',
        campusCardAvailable: '',
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        fields1: [
          {
            key: 'name',
            value: '',
            type: 'text',
            validate: 'required|requiredTypeName',
            customerValidate: '',
          },
          {
            key: 'furiganaName',
            value: '',
            type: 'text',
            validate: 'required|katakanaType',
            customerValidate: '',
          },
          {
            key: 'dob',
            value: '',
            type: 'date-text',
            max_date: moment().format('YYYY-MM-DD'),
            validate: 'required|dateDob|rangeDate',
            placeholder: 'placeholder.dob',
            menu: false,
            customerValidate: '',
          },
          {
            key: 'sex',
            items: [
              { value: 1, text: '男' },
              { value: 0, text: '女' },
            ],
            value: 1,
            type: 'select',
            validate: 'required',
            customerValidate: '',
          },
          {
            key: 'email',
            value: '',
            type: 'text',
            validate: 'email',
            customerValidate: '',
          },
          {
            key: 'contactPhone',
            value: '',
            type: 'text',
            customerValidate: '',
            validate: '',
          },
          {
            key: 'contactAddress',
            value: '',
            type: 'text',
            customerValidate: '',
          },
          {
            label: 'fixed',
            key: 'fixed',
            value: false,
            type: 'checkbox',
            customerValidate: '',
          },
        ],
        fields2: [
          {
            key: 'collegeId',
            items: [],
            value: '',
            validate: '',
            type: 'autocomplete',
            placeholder: 'placeholder.schoolNamePlaceholder',
            disable: false,
          },
          {
            key: 'campusCardValidDate',
            value: '',
            type: 'date-text',
            max_date: moment().format('YYYY-MM-DD'),
            validate: 'dateResidenceCard',
            placeholder: 'placeholder.dob',
            menu: false,
            disable: false,
          },
          {
            key: 'serviceInfo',
            value: '',
            type: 'text',
            customerValidate: '',
            disable: false,
          },
        ],
        fields3: [
          {
            key: 'language',
            value: '',
            type: 'text',
            validate: 'required',
            customerValidate: '',
          },
          {
            key: 'visaId',
            items: [],
            value: '',
            type: 'select',
            validate: '',
            customerValidate: '',
            disable: false,
          },
          {
            key: 'residenceCardId',
            value: '',
            type: 'text',
            customerValidate: '',
            disable: false,
          },
          {
            key: 'residenceCardValidDate',
            value: '',
            type: 'date-text',
            max_date: moment().format('YYYY-MM-DD'),
            validate: 'dateResidenceCard',
            placeholder: 'placeholder.dob',
            menu: false,
            customerValidate: '',
            disable: false,
          },
        ],
        fields4: [
          {
            key: 'bank',
            value: '',
            type: 'text',
          },
          {
            key: 'bankBranch',
            value: '',
            type: 'text',
          },
          {
            key: 'cardNumber',
            value: '',
            type: 'text',
          },
          {
            key: 'accountHolder',
            value: '',
            type: 'text',
          },
        ],
        dialogOcr: false,
        docsList: [],
        dialogCheckDoc: false,
        lengthDocs: '0',
        processCount: 0,
        checkUserName: '',
        checkPassword: '',
        checkCollegeId: '',
        dialogCheckFile: false,
        processCountFile: 0,
        lengthFiles: '0/6',
        idParttime: '',
        // add customer fixed
        dateInWorks: [],
        dateInWork: {
          workDate: [],
          jobId: '',
          shiftId: '',
          errorShiftId: '',
          errorJobId: '',
          errorWorkDate: false,
          listShift: [],
        },
        itemsDay: [
          { value: 0, text: '日' },
          { value: 1, text: '月' },
          { value: 2, text: '火' },
          { value: 3, text: '水' },
          { value: 4, text: '木' },
          { value: 5, text: '金' },
          { value: 6, text: '土' },
        ],
        mockitem: [
          { value: 1, text: '男' },
          { value: 0, text: '女' },
        ],
        itemsShift: [],
        customerName: null,
        // jobName: [],
        itemsCustomer: [
          {
            code: '',
            name: '',
            id: null,
          },
        ],
        listJobs: [],
        listShift: [],
        errorCustomerName: null,
        language: '',
        visaId: '',
        visaStudentId: '',
      }
    },
    computed: {
      ...get('parttimer', [
        'message',
        'status',
        'error',
        'colleges',
        'dataOcrSave',
        'newParttimeEmployee',
      ]),
      ...get('visa', ['listVisa', 'visaInfo']),
      listCustomer: get('customer@list'),
    },
    watch: {
      error (value) {
        if (value) {
          this.snackbarMessage = this.$t(value)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
        this.idParttime = ''
      },
      listCustomer (value) {
        this.itemsCustomer = value.data.customers
      },
      customerName (value) {
        this.dateInWorks = [{ ...this.dateInWork }]
        const data = this.itemsCustomer.find((i) => i.id === value)
        this.listJobs = data ? [...data.jobs] : []
        this.listShift = []
      },

      message (value) {
        if (value === 'success') {
        // this.$router.push({ path: '/parttimer-list', query: { success: true } })
        }
      },
      colleges (value) {
        const field = this.fields2.find(o => o.key === 'collegeId')
        value.forEach(item => {
          field.items.push({
            value: item.id,
            text: item.name,
          })
        })
      },
      listVisa (value) {
        const field = this.fields3.find(o => o.key === 'visaId')
        value.map(item => {
          if (item.type === '留学') {
            this.visaStudentId = item.id
          }
          field.items.push({
            value: item.id,
            text: item.type,
          })
        })
      },
      visaInfo (value) {
        this.docsList = value.documents
        const totalDocs = this.docsList.length
        const docsCheckList = []
        this.docsList.map(item => {
          delete item.createdAt
          delete item.updatedAt
          delete item.description
          if (item.isFulfiled === true) {
            docsCheckList.push(item)
          }
        })
        const totalChecked = docsCheckList.length
        this.lengthDocs = totalChecked + '/' + totalDocs
        this.processCount = Math.round((totalChecked / totalDocs) * 100)
      },
      dataOcrSave (value) {
        if (value.name) {
          this.dialogOcr = false
          for (let [key, val] of Object.entries(value)) {
            const field =
              this.fields1.find(o => o.key === key) ||
              this.fields2.find(o => o.key === key) ||
              this.fields3.find(o => o.key === key) ||
              this.fields4.find(o => o.key === key)
            if (field) {
              if (
                key === 'dob' ||
                key === 'residenceCardValidDate'
              ) {
                if (val) {
                  val = moment(val).format('YYYY-MM-DD')
                }
              }
              if (key === 'sex') {
                val = val === '女' ? 0 : 1
              }
              if (key === 'visaId') {
                this.listVisa.map(item => {
                  if (item.type === val) {
                    val = item.id
                  }
                })
              }
              field.value = val
            }
          }
        }
      },
      newParttimeEmployee (value) {
        this.$store.dispatch(
          'parttimer/getNewPass',
          value.data.parttimeEmployee.user.password,
        )
        this.idParttime = value.data.parttimeEmployee.id
      },
      language (val) {
        if (val === '日本' || val === '日本人') {
          this.fields3.find((o) => o.key === 'visaId').disable = true
          this.fields3.find((o) => o.key === 'residenceCardId').disable = true
          this.fields3.find((o) => o.key === 'residenceCardValidDate').disable = true
          this.visaId = ''
          this.fields3.find((o) => o.key === 'residenceCardId').value = ''
          this.fields3.find((o) => o.key === 'residenceCardValidDate').value = ''
        } else {
          this.fields3.find((o) => o.key === 'visaId').disable = false
          this.fields3.find((o) => o.key === 'residenceCardId').disable = false
          this.fields3.find((o) => o.key === 'residenceCardValidDate').disable = false
        }
      },
      visaId (val) {
        if (val === this.visaStudentId) {
          this.fields2.find((o) => o.key === 'collegeId').disable = false
          this.fields2.find((o) => o.key === 'campusCardValidDate').disable = false
        } else {
          this.fields2.find((o) => o.key === 'collegeId').disable = true
          this.fields2.find((o) => o.key === 'campusCardValidDate').disable = true
          this.fields2.find((o) => o.key === 'collegeId').value = null
          this.fields2.find((o) => o.key === 'campusCardValidDate').value = ''
        }
      },
    },
    created () {
      this.$store.dispatch('visa/getVisa')
      const params = {
        isFixed: true,
      }
      this.$store.dispatch('customer/getCustomers', params)
      this.dateInWorks.push({ ...this.dateInWork })
    },
    mounted () {
      this.$store.dispatch('parttimer/getCollege')
      if (this.dialogOcr === true) {
        setTimeout(function () { this.dialogOcr = false }.bind(this), 300000)
      }
      // Define the rule globally
      extend('required', {
        ...required,
        message: this.$t('validator.required'),
      })
      extend('katakanaType', {
        validate (value) {
          // regex check characters Katakana
          return /^[ｧ-ﾝﾞﾟ]+$/.test(value)
        },
        message: this.$t('validator.katakanaHaftWidthInvalid'),
      })
      extend('requiredTypeName', {
        validate (value) {
          // regex check characters Hiragana, Katakana, Kanji
          return /^[ぁ-んァ-ン一-龥 A-Z]+$/.test(value)
        },
        message: this.$t('validator.requiredTypeName'),
      })
      extend('email', {
        ...email,
        message: this.$t('validator.email_invalid'),
      })

      extend('number', {
        validate (value) {
          return /^\+?(0|[1-9]\d*)$/.test(value)
        },
        message: this.$t('validator.must_be_number'),
      })
      extend('min', {
        ...min,
        message: this.$t('validator.password_invalid'),
      })
      extend('dateDob', {
        validate (value) {
          return /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(value)
        },
        message: this.$t('生年月日は正確な形式で入力してください。'),
      })
      extend('dateResidenceCard', {
        validate (value) {
          return /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(value)
        },
        message: this.$t('存在しない年月日が入力されています。'),
      })
      extend('password', {
        params: ['target'],
        validate (value, target) {
          if (typeof target === 'object') return value === target[0]
          else if (typeof target === 'string' || typeof target === 'number') {
            return value === target
          }
        },
        message: this.$t('validator.confirm_password_invalid'),
      })
      extend('rangeDate', {
        validate (value) {
          if (moment(value).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')) { return false }
          return true
        },
        message: this.$t('過去の日付を入力してください。'),
      })
    },
    methods: {
      handleClearIdParttime () {
        this.idParttime = ''
      },
      hanldleUpdateLengFile (length) {
        this.lengthFiles = length + '/6'
        this.processCountFile = Math.round((length / 6) * 100)
      },
      closeDialogFile () {
        this.dialogCheckFile = false
      },
      saveValueDate (value) {
        this.$refs['menu' + value.key][0].save(value.value)
      },
      expiryDate (dateString) {
        var expiration = moment(dateString).format('YYYY-MM-DD')
        var currentDate = moment().format('YYYY-MM-DD')
        var days = moment(expiration).diff(currentDate, 'days')
        return days
      },
      onSubmit () {
        const isvalidForm = !this.checkValidate()
        if (this.fields1[7].value) {
          const isvalidFixed = this.checkValidateParttimeFixed()
          if (isvalidForm && isvalidFixed) {
            this.callApi()
          }
        } else {
          if (isvalidForm) {
            this.callApi()
          }
        }
      },
      callApi: debounce(function () {
        let payload = {}
        this.age = this.$refs.datedob[0].value
        this.residenceCardAvailable = this.$refs.dateresidenceCardValidDate[0].value
        this.campusCardAvailable = this.$refs.datecampusCardValidDate[0].value
        payload = {
          name: this.fields1.find(o => o.key === 'name').value,
          furiganaName: this.fields1.find(o => o.key === 'furiganaName').value,
          dob: this.fields1.find(o => o.key === 'dob').value,
          sex: this.fields1.find(o => o.key === 'sex').value,
          email: this.fields1.find(o => o.key === 'email').value,
          contactPhone: this.fields1.find(o => o.key === 'contactPhone').value,
          age: this.age,
          contactAddress: this.fields1.find(o => o.key === 'contactAddress')
            .value,
          serviceInfo: this.fields2.find(o => o.key === 'serviceInfo').value,
          collegeId: this.fields2.find(o => o.key === 'collegeId').value,
          campusCardValidDate: this.fields2.find(
            o => o.key === 'campusCardValidDate',
          ).value,
          campusCardAvailable: this.campusCardAvailable.toString(),
          language: this.language,
          visaId: this.visaId,
          residenceCardId: this.fields3.find(o => o.key === 'residenceCardId')
            .value,
          residenceCardValidDate: this.fields3.find(
            o => o.key === 'residenceCardValidDate',
          ).value,
          residenceCardAvailable: this.residenceCardAvailable.toString(),
          bankBranch: this.fields4.find(o => o.key === 'bankBranch').value,
          bank: this.fields4.find(o => o.key === 'bank').value,
          cardNumber: this.fields4.find(o => o.key === 'cardNumber').value,
          accountHolder: this.fields4.find(o => o.key === 'accountHolder').value,
          isActive: 1,
          isFixed: this.fields1[7].value,
          docsList: this.docsList,
        }
        if (payload.isFixed) {
          payload.customerId = this.customerName
          payload.fixedShiftData = []
          this.dateInWorks.forEach(i => {
            payload.fixedShiftData.push({
              jobId: i.jobId,
              shiftId: i.shiftId,
              workdate: i.workDate,
            })
          })
        }
        this.$store.dispatch('parttimer/create', payload)
      }, 500),
      checkDate (evt) {
        const field = this.fields1.find(o => {
          if (
            o.key === 'dob' ||
            o.key === 'campusCardValidDate' ||
            o.key === 'residenceCardValidDate'
          ) {
            return o
          }
        })
        if (
          (evt.keyCode >= 48 && evt.keyCode <= 57) ||
          (evt.keyCode >= 96 && evt.keyCode <= 105) ||
          evt.keyCode === 231
        ) {
          evt = evt || window.event

          if (field.value) {
            var size = field.value.length
            if (size >= 10) field.value = field.value.slice(0, -1)
            if (
              (size === 4 && field.value > moment().year()) ||
              (size === 7 &&
                (Number(field.value.split('-')[1]) > 12 ||
                  Number(field.value.split('-')[1]) < 1)) ||
              (size === 10 &&
                (Number(field.value.split('-')[2]) > 31 ||
                  Number(field.value.split('-')[1]) < 1))
            ) {
              field.value = ''
              // add alert funct
              return
            }

            if (
              (size === 4 && field.value < moment().year()) ||
              (size === 7 && Number(field.value.split('-')[1]) < 13)
            ) {
              field.value += '-'
            }
          }
        }
      },
      openCheckDoc () {
        this.dialogCheckDoc = true
      },
      getVisaDocs (item) {
        this.$store.dispatch('visa/getVisaById', { id: item })
      },
      saveDocs () {
        this.dialogCheckDoc = false
        const totalDocs = this.docsList.length
        const docsCheckList = []
        this.docsList.map(item => {
          if (item.isFulfiled === true) {
            docsCheckList.push(item)
          }
        })
        const totalChecked = docsCheckList.length
        this.lengthDocs = totalChecked + '/' + totalDocs
        this.processCount = Math.round((totalChecked / totalDocs) * 100)
        // const payload = {
        //   id: this.$route.params.id,
        //   results: this.docsList,
        // }
        // this.$store.dispatch('employee/updatePersionalVisa', payload)
      },
      reloadError () {
        this.checkCollegeId = ''
        this.fields1.forEach(field => {
          field.customerValidate = ''
        })
        this.fields3.forEach(field => {
          field.customerValidate = ''
        })
      },
      checkValidate () {
        this.reloadError()
        let error = false
        const validateNameTypeRegex = /^[ぁ-んァ-ン一-龥 A-Z]+$/
        const validateKatakanaRegex = /^[ｧ-ﾝﾞﾟ]+$/
        if (!this.fields1[0].value.trim()) {
          this.fields1[0].customerValidate = this.$t('validator.required')
          error = true
        }
        if (!validateNameTypeRegex.test(this.fields1[0].value)) {
          error = true
        }
        if (!this.fields1[1].value.trim()) {
          this.fields1[1].customerValidate = this.$t('validator.required')
          error = true
        }
        if (!validateKatakanaRegex.test(this.fields1[1].value)) {
          error = true
        }
        if (!this.fields1[2].value.trim()) {
          this.fields1[2].customerValidate = this.$t('validator.required')
          error = true
        } else if (
          !/^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(
            this.fields1[2].value,
          )
        ) {
          error = true
        } else if (
          moment(this.fields1[2].value).format('YYYY-MM-DD') >=
          moment().format('YYYY-MM-DD')
        ) {
          error = true
        }
        if (
          this.fields1[5].value.trim() &&
          !/^[+]?[(]?[0-9]{4}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,5}$/.test(
            this.fields1[5].value,
          )
        ) {
          error = true
        }
        if (
          this.fields2[1].value.trim() &&
          !/^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(
            this.fields2[1].value,
          )
        ) {
          error = true
        }
        if (!this.language.trim()) {
          this.fields3[0].customerValidate = this.$t('validator.required')
          error = true
        }
        return error
      },
      addFields () {
        this.dateInWorks.push({ ...this.dateInWork })
      },
      subtractFields () {
        this.dateInWorks.pop()
      },
      checkValidateParttimeFixed () {
        var isValid = false
        this.errorCustomerName = !this.customerName ? this.$t('validator.required') : null
        // if (this.fields1[7].value) {
        this.dateInWorks.map((i) => {
          if (i.jobId && i.shiftId && i.workDate.length >= 1) {
            i.isValid = true
          } else {
            i.isValid = false
          }
          i.errorJobId = !i.jobId ? this.$t('validator.required') : null
          i.errorShiftId = !i.shiftId ? this.$t('validator.required') : null
          i.errorWorkDate = i.workDate.length < 1
          return i
        })
        isValid = this.dateInWorks.every((i) => i.isValid === true)
        return isValid
      },
      changeJob (value, index) {
        const data = this.listJobs.find((i) => i.id === value)
        this.dateInWorks[index].listShift = [...data.shifts]
        this.dateInWorks[index].shiftId = ''
      },
    },
  }
</script>
<style lang="scss" scoped>
.title-bank {
  color: #4262ff;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
}
.chip {
  width: 412px;
  height: 127px;
  border-radius: 12px;
  margin-left: 50px;
  font-size: 18px;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }

  .v-icon {
    margin-left: 100px;
    background: rgba(197, 220, 250, 0.5);
    padding: 8% 14% 10% 14%;
    border-radius: 30px;
    color: gray;
  }
}
</style>
